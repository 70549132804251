@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';

:root{
  --font-family: 'Inter', sans-serif;
  --color-bg: #F5F5F5;
  --color-nl: #172837;
  --color-active:#BC2F3A;
}
.center-load{
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.ring{
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  animation: ring 2s linear infinite;
}
@keyframes ring {
  0%{
    transform: rotate(0deg);
    box-shadow: 1px 5px 2px #BC2F3A;
  }
  50%{
    transform: rotate(180deg);
    box-shadow: 1px 5px 2px #BC2F3A;
  }
  100%{
    transform: rotate(360deg);
    box-shadow: 1px 5px 2px #BC2F3A;
  }
}
.ring::before{
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(255, 255, 255, .3);
}
.loading{
  font-size: 20px;
  color: #737373;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 200px;
  animation: text 3s ease-in-out infinite;
}
@keyframes text {
  50%{
    color: #F5F5F5;
  }
}